export function setTitle(title: string) {
  document.title = title
}

// 获取URL参数
export function getQueryVariable(name) {
  // const query = window.location.search.substring(1)
  // const vars = query.split('&')
  // for (let i = 0; i < vars.length; i += 1) {
  //   const pair = vars[i].split('=')
  //   if (pair[0] === variable) {
  //     return pair[1]
  //   }
  // }
  // return ''

  const href = location.href
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(href) || ['', ''])[1].replace(
        /\+/g,
        '%20'
      )
    ) || ''
  )
}

export function getToken() {
  return window.localStorage.getItem('gzh_course_usertoken') || ''
}
